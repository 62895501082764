import { Portal } from '@daily/shared/components/Portal';
import { DailyAudio, useLocalSessionId } from '@daily-co/daily-react';
import { useCallback, useEffect, useState } from 'react';

import { useFilteredParticipantIds } from '/contexts/ParticipantsProvider';
import { MAX_RECENT_SPEAKER_COUNT } from '/contexts/TracksProvider';
import { isMediaElementPlaying } from '/lib/dom';
import { captureAudioPlayException } from '/lib/sentry-capture-helpers';
import { useAutoplayFailedModal } from '/lib/state/modals';

export const FlownAudioTracks: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [, setShowAutoplayFailedModal] = useAutoplayFailedModal();
  const filteredParticipantIds = useFilteredParticipantIds();
  const [isClient, setIsClient] = useState(false);
  const localSessionId = useLocalSessionId();

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    const playTracks = () => {
      Array.from(document.querySelectorAll('.audioTracks audio'))
        .filter((audio: HTMLAudioElement) => Boolean(audio.srcObject))
        .forEach((audio: HTMLAudioElement) => {
          const playAudio = () => {
            const promise = audio.play();
            if (promise !== undefined) {
              promise
                .then(() => {
                  // All good, audio is playing
                })
                .catch((error) => {
                  // Audio play still failing
                  console.warn('Failed to play audio', error);
                  if (
                    error instanceof DOMException &&
                    error.name === 'NotAllowedError'
                  ) {
                    setShowAutoplayFailedModal(true);
                  }
                  captureAudioPlayException(error, audio, localSessionId, {
                    audioTracks: 1,
                  });
                });
            }
          };
          if (!isMediaElementPlaying(audio)) {
            playAudio();
          }
        });
    };

    navigator.mediaDevices.addEventListener('devicechange', playTracks);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', playTracks);
    };
  }, [localSessionId, setShowAutoplayFailedModal]);

  const handlePlayFailed = useCallback(() => {
    setShowAutoplayFailedModal(true);
  }, [setShowAutoplayFailedModal]);

  // Only render audio tracks in browser
  // We want the tracks to be there even when disable audio is true
  // Users should be able to hear but not unmute
  if (!isClient) return null;

  return (
    <Portal key="AudioTracks">
      <div className="audioTracks">
        <DailyAudio
          autoSubscribeActiveSpeaker={filteredParticipantIds === null}
          maxSpeakers={MAX_RECENT_SPEAKER_COUNT}
          onPlayFailed={handlePlayFailed}
        />
        <style jsx>{`
          .audioTracks {
            position: absolute;
            visibility: hidden;
          }
        `}</style>
      </div>
    </Portal>
  );
};
