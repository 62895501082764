import { LoadingSpinner } from '@daily/shared/components/LoadingSpinner';
import { useTheme } from '@daily/shared/contexts/Theme';

import { Layout } from './Layout';

export const LoadingScreen: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { colors } = useTheme();

  return (
    <Layout>
      <div>
        <LoadingSpinner />
      </div>
      <style jsx>{`
        div {
          align-items: center;
          display: flex;
          height: 100vh;
          justify-content: center;
          width: 100%;
          background: ${colors.custom.mainAreaBg};
          color: ${colors.accentText};
        }
      `}</style>
    </Layout>
  );
};
