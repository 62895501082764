import { Portal } from '@daily/shared/components/Portal';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useDaily, useScreenShare } from '@daily-co/daily-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  clearInterval as workerClearInterval,
  setInterval as workerSetInterval,
} from 'worker-timers';

import {
  useCurrentSpeakerId,
  useOrderedParticipantIds,
} from '/contexts/ParticipantsProvider';
import { useActiveSpeaker } from '/hooks/useActiveSpeaker';
import { useScreens } from '/hooks/useScreens';
import { usePinnedId, useViewMode } from '/lib/state/layout';

const PIP_FPS = 15;

interface Props extends React.VideoHTMLAttributes<HTMLVideoElement> {
  enabled?: boolean;
  onEnterPIP?(): void;
  onLeavePIP?(): void;
}

/**
 * captureStream is still working draft and not ported to official types, yet.
 * https://stackoverflow.com/questions/50651091/unresolved-method-capturestream-on-htmlcanvaselement
 */
interface CanvasElement extends HTMLCanvasElement {
  captureStream(frameRate?: number): MediaStream;
}

/**
 * Renders videos in current viewMode in a hidden video element.
 * Compositing is done on the client using an invisible HTMLCanvasElement.
 */
export const PictureInPicture = forwardRef<HTMLVideoElement, Props>(
  function PictureInPicture(
    { enabled = false, onEnterPIP, onLeavePIP, ...videoProps },
    pipRef: React.MutableRefObject<HTMLVideoElement>
  ) {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const daily = useDaily();
    const canvasRef = useRef<CanvasElement>(null);
    const canvasStream = useRef<MediaStream>(null);
    const viewMode = useViewMode();
    const orderedParticipantIds = useOrderedParticipantIds();
    const currentSpeakerId = useCurrentSpeakerId();
    const activeSpeakerId = useActiveSpeaker();
    const [pinnedId] = usePinnedId();
    const { isSharingScreen } = useScreenShare();
    const screens = useScreens();
    const [shouldDraw, setShouldDraw] = useState(enabled);

    useEffect(() => {
      setShouldDraw(enabled);
    }, [enabled]);

    /**
     * Setup enter/leave event listeners.
     */
    useEffect(() => {
      const pipVideo = pipRef.current;
      if (!pipVideo) return;
      const handleEnterPIP = () => {
        if (pipVideo.paused) pipVideo.play();
        onEnterPIP?.();
      };
      const handleLeavePIP = () => {
        onLeavePIP?.();
      };
      pipVideo.addEventListener('enterpictureinpicture', handleEnterPIP);
      pipVideo.addEventListener('leavepictureinpicture', handleLeavePIP);
      return () => {
        pipVideo.removeEventListener('enterpictureinpicture', handleEnterPIP);
        pipVideo.removeEventListener('leavepictureinpicture', handleLeavePIP);
      };
    }, [onEnterPIP, onLeavePIP, pipRef]);

    /**
     * Create Canvas.
     */
    useEffect(() => {
      canvasRef.current = document.createElement('canvas') as CanvasElement;
      canvasRef.current.width = 1280;
      canvasRef.current.height = 720;
      canvasStream.current = canvasRef.current.captureStream(PIP_FPS);
      return () => {
        canvasRef.current.remove();
        canvasRef.current = null;
        canvasStream.current = null;
      };
    }, []);

    const [logo, setLogo] = useState<HTMLImageElement | null>(null);
    useEffect(() => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = '/flown-logo.svg'; // Replace with your actual logo URL
      img.onload = () => {
        setLogo(img);
      };
    }, []);

    useEffect(
      function setupPiPStream() {
        const canvas = canvasRef.current;
        const pipVideo = pipRef.current;
        if (!canvas || !pipVideo) return;
        if (pipVideo.srcObject !== canvasStream.current) {
          pipVideo.srcObject = canvasStream.current;
        }
      },
      [pipRef]
    );

    /**
     * Initiate the draw loop.
     * TODO: Move rendering to [OffscreenCanvas](https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas).
     */
    useEffect(() => {
      const canvas = canvasRef.current;
      const pipVideo = pipRef.current;
      if (!canvas || !pipVideo) return;
      const canvasContext = canvas.getContext('2d');

      if (!shouldDraw) {
        const cWidth = canvas.width;
        const cHeight = canvas.height;
        canvasContext.clearRect(0, 0, cWidth, cHeight);
        return;
      }

      const draw = () => {
        const cWidth = canvas.width;
        const cHeight = canvas.height;
        canvasContext.clearRect(0, 0, cWidth, cHeight);

        /**
         * Returns truncated text, based on passed maxWidth.
         */
        const fittingString = (
          ctx: CanvasRenderingContext2D,
          text: string,
          maxWidth: number
        ) => {
          var width = ctx.measureText(text).width;
          var ellipsis = '…';
          var ellipsisWidth = ctx.measureText(ellipsis).width;
          if (width <= maxWidth || width <= ellipsisWidth) {
            return text;
          } else {
            var len = text.length;
            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
              text = text.substring(0, len);
              width = ctx.measureText(text).width;
            }
            return text + ellipsis;
          }
        };

        const drawMuted = (
          name: string,
          x: number,
          y: number,
          w: number,
          h: number
        ) => {
          canvasContext.fillStyle = colors.custom.mainAreaBgAccent;
          canvasContext.fillRect(x, y, w, h);
          canvasContext.font = `bold ${w / 16}px sans-serif`;
          canvasContext.textBaseline = 'middle';
          canvasContext.textAlign = 'center';
          canvasContext.fillStyle = colors.custom.mainAreaText;
          const maxWidth = 0.8 * w;
          canvasContext.fillText(
            fittingString(canvasContext, name, maxWidth),
            x + w / 2,
            y + h / 2,
            maxWidth
          );
        };

        const drawVideo = (
          video: HTMLVideoElement,
          x: number,
          y: number,
          w: number,
          h: number,
          name: string
        ) => {
          if (!video) return;
          const stream = video.srcObject as MediaStream;
          if (video.paused || !stream?.getVideoTracks?.()?.[0]?.enabled) {
            // Participant is muted
            drawMuted(name, x, y, w, h);
            return;
          }
          const videoAR = video.videoWidth / video.videoHeight;
          // Determine centered position, in case video doesn't match 16:9 aspect ratio
          const centeredW = videoAR < 16 / 9 ? h * videoAR : w;
          const centeredX = videoAR < 16 / 9 ? x + (w - centeredW) / 2 : x;
          const centeredH = videoAR > 16 / 9 ? w / videoAR : h;
          const centeredY = videoAR > 16 / 9 ? y + (h - centeredH) / 2 : y;
          // Apply mirroring on Canvas
          const isMirrored = video.classList.contains('isMirrored');
          // Correct X position when potentially flipped
          const rx = isMirrored ? cWidth - (centeredX + centeredW) : centeredX;
          /**
           * We can't easily render a flipped image, but we can flip the canvas,
           * render the image and flip the canvas back again!
           * It's like painting on a transparent sheet of paper 🧑‍🎨
           */
          if (isMirrored) {
            /**
             * Flip the canvas.
             * .scale() happens at x=0,y=0.
             * .translate() shifts the canvas back in view.
             */
            canvasContext.translate(cWidth, 0);
            canvasContext.scale(-1, 1);
          }
          canvasContext.drawImage(video, rx, centeredY, centeredW, centeredH);
          if (isMirrored) {
            /**
             * Flip the canvas again.
             */
            canvasContext.scale(-1, 1);
            canvasContext.translate(-cWidth, 0);
          }
        };

        const drawParticipant = (
          id: string,
          x: number,
          y: number,
          w: number,
          h: number
        ) => {
          const tile = document.querySelector<HTMLDivElement>(
            `.tile[id="${id}"]`
          );
          const hasVideo = !tile?.classList?.contains('noVideo');
          const video = tile?.querySelector('video');
          if (hasVideo && video) {
            drawVideo(video, x, y, w, h, getName(tile));
          } else {
            drawMuted(getName(tile), x, y, w, h);
          }
        };

        const drawActiveBorder = (
          x: number,
          y: number,
          w: number,
          h: number
        ) => {
          canvasContext.beginPath();
          canvasContext.moveTo(x, y);
          canvasContext.lineTo(x + w, y);
          canvasContext.lineTo(x + w, y + h);
          canvasContext.lineTo(x, y + h);
          canvasContext.lineTo(x, y);
          canvasContext.lineWidth = 4;
          canvasContext.strokeStyle = colors.system.yellow;
          canvasContext.stroke();
        };

        const participants = daily.participants();
        const getName = (tile: HTMLDivElement) => {
          if (!tile) return '';
          const id = tile.getAttribute('id');
          const name =
            participants?.local?.session_id === id
              ? participants?.local?.user_name
                ? t('people.you')
                : t('people.youGuest')
              : participants[id]?.user_name || t('people.guest');
          return name;
        };

        const shouldDrawGrid = viewMode === 'grid' || isSharingScreen;

        if (shouldDrawGrid) {
          const selector =
            viewMode === 'grid'
              ? '.grid .tile:not(.local)'
              : '.speaker .sidebar .tile:not(.local)';
          const elements = Array.from(document.querySelectorAll(selector));
          const localVideo = document.querySelector(
            '.tile.local video'
          ) as HTMLVideoElement;
          // Show local screenshare in PiP window if the call is empty.
          if (isSharingScreen && localVideo && !elements.length) {
            elements.push(localVideo);
          }
          const n = elements.length;
          const cols = Math.ceil(Math.sqrt(n));
          const rows = Math.ceil(n / cols);
          const width = cWidth / cols;
          const height = width * (9 / 16);
          const dy = (cHeight - rows * height) / 2;
          let active: number[];
          elements.forEach((el, i) => {
            const tile = el.closest('[id]');
            const id = tile.getAttribute('id');
            const col = i % cols;
            const row = Math.floor(i / cols);
            const dx =
              row === rows - 1 && n % cols > 0
                ? (cWidth - (n % cols) * width) / 2
                : 0;
            const x = dx + col * width;
            const y = dy + row * height;
            drawParticipant(id, x, y, width, height);
            if (id === activeSpeakerId) {
              active = [x, y, width, height];
            }
          });
          if (active) drawActiveBorder.call(this, ...active);
        } else if (screens.length) {
          const screenVideo = document.querySelector<HTMLVideoElement>(
            `[id="${screens[0]?.screenId}"] video`
          );
          const gap = 4;
          const screenSpace = cWidth * 0.7;
          const screenWidth = screenSpace - gap;
          if (screenVideo) {
            const screenAR = screenVideo?.videoWidth / screenVideo?.videoHeight;
            const screenHeight = screenWidth / screenAR;
            if (screenAR > (16 * 0.7) / 9) {
              // In case screen is more landscape-y than available area,
              // render screen centered.
              drawVideo(
                screenVideo,
                0,
                (cHeight - screenHeight) / 2,
                screenWidth,
                screenHeight,
                ''
              );
            } else {
              // Screen is more portrait-y than available area,
              // video is centered horizontally automatically.
              drawVideo(screenVideo, 0, 0, cWidth * 0.7, cHeight, '');
            }
          }
          const participantWidth = cWidth * 0.3;
          const participantHeight = (participantWidth * 9) / 16;
          drawParticipant(
            activeSpeakerId ?? orderedParticipantIds[0],
            cWidth * 0.7,
            0,
            participantWidth,
            participantHeight
          );
          if (
            pinnedId &&
            pinnedId !== activeSpeakerId &&
            !pinnedId.endsWith('-screen')
          ) {
            drawParticipant(
              pinnedId,
              cWidth * 0.7,
              participantHeight + gap,
              participantWidth,
              participantHeight
            );
          }
        } else {
          drawParticipant(currentSpeakerId, 0, 0, cWidth, cHeight);
        }

        const drawLogo = () => {
          if (logo) {
            const padding = 10;
            const margin = 20;
            const logoWidth = 205;
            const logoHeight = 35;

            canvasContext.fillStyle = 'black';
            canvasContext.fillRect(
              margin,
              margin,
              logoWidth + 2 * padding,
              logoHeight + 2 * padding
            );
            canvasContext.drawImage(
              logo,
              margin + padding,
              margin + padding,
              logoWidth,
              logoHeight
            );
          }
        };

        drawLogo();
      };

      const interval = workerSetInterval(draw, 1000 / PIP_FPS);

      return () => {
        workerClearInterval(interval);
      };
    }, [
      activeSpeakerId,
      colors.custom.mainAreaBgAccent,
      colors.custom.mainAreaText,
      colors.system.yellow,
      currentSpeakerId,
      daily,
      isSharingScreen,
      orderedParticipantIds,
      logo,
      pinnedId,
      pipRef,
      screens,
      shouldDraw,
      t,
      viewMode,
    ]);

    return (
      <>
        <Portal>
          <video
            {...videoProps}
            id="pip-video"
            autoPlay
            playsInline
            ref={pipRef}
          />
        </Portal>
        <style jsx>{`
          #pip-video {
            height: 24px;
            right: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: calc(var(--banner-height) + 4px);
          }
        `}</style>
      </>
    );
  }
);
PictureInPicture.displayName = 'PictureInPicture';
