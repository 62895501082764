import { Button } from '@daily/shared/components/Button';
import { AdminIcon } from '@daily/shared/components/Icons/AdminIcon';
import { CamIcon } from '@daily/shared/components/Icons/CamIcon';
import { CloseIcon } from '@daily/shared/components/Icons/CloseIcon';
import { MicrophoneIcon } from '@daily/shared/components/Icons/MicrophoneIcon';
import { Text } from '@daily/shared/components/Text';
import { useSnackbar } from '@daily/shared/contexts/Snackbar';
import { useTheme } from '@daily/shared/contexts/Theme';
import {
  useDaily,
  useDailyEvent,
  useDevices,
  useLiveStreaming,
  useLocalSessionId,
  useParticipantIds,
  useParticipantProperty,
  useThrottledDailyEvent,
} from '@daily-co/daily-react';
import { captureException } from '@sentry/browser';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AutoChunkLoader } from '/components/ChunkLoader';
import { WaitingForHost } from '/components/WaitingForHost';
import { useCallState } from '/contexts/CallProvider';
import { PictureInPicture } from '/features/picture-in-picture/components/PictureInPicture';
import { WaitingRoomProvider } from '/features/waiting-room/contexts/WaitingRoomProvider';
import { FlownAudioTracks } from '/flown/components/FlownAudioTracks';
import { useLocalUserFlownUserData } from '/flown/hooks/useFlownUserData';
import {
  useIsAutoPIPEnabled,
  useIsPIP,
  useSetIsAutoPIPEnabled,
  useSetIsPIP,
} from '/flown/state/custom';
import { useAudioProcessor } from '/hooks/useAudioProcessor';
import { useBackgroundEffect } from '/hooks/useBackgroundEffect';
import { useBandwidthControls } from '/hooks/useBandwidthControls';
import { useCallConfig } from '/hooks/useCallConfig';
import { useDebugging } from '/hooks/useDebugging';
import { usePreviousValue } from '/hooks/usePreviousValue';
import { useSoundLoader } from '/hooks/useSoundLoader';

import { FinalMessage } from './FinalMessage';
import { Layout } from './Layout';
import { LoadingScreen } from './LoadingScreen';
import { useIntercomIntegration } from './useIntercomIntegration';

const Call = dynamic(() => import('/components/Call').then((mod) => mod.Call), {
  loading: AutoChunkLoader,
});
const Haircheck = dynamic(
  () => import('/components/Haircheck').then((mod) => mod.Haircheck),
  {
    loading: AutoChunkLoader,
  }
);

const FlownTray = dynamic(
  () => import('/flown/components/FlownTray').then((mod) => mod.FlownTray),
  {
    loading: AutoChunkLoader,
  }
);

export const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { addMessage } = useSnackbar();
  const { mode, reloadCall, state } = useCallState();
  const { broadcast, broadcastRole } = useCallConfig();
  const { camState, micState } = useDevices();
  const [disableWaitingForHostMsg, setDisableWaitingForHostMsg] =
    useState(false);
  const ownerParticipantIds = useParticipantIds({ filter: 'owner' });
  const { poweredByDaily } = useCallConfig();
  const { errorSound } = useSoundLoader();
  const { disablePrebuiltDebugger, enablePrebuiltDebugger } = useDebugging();
  const { query } = useRouter();
  const daily = useDaily();

  const pipRef = useRef<HTMLVideoElement>(null);

  useBandwidthControls();

  useIntercomIntegration();

  /**
   * Applies stored background effect when the camera is started.
   */
  useBackgroundEffect();

  /**
   * Applies stored audio processor when the camera is started
   */
  const { currentAudioProcessor, setAudioProcessor } = useAudioProcessor();
  useDailyEvent(
    'started-camera',
    useCallback(async () => {
      if (!daily) return;
      const inputSettings = await daily.getInputSettings();

      if (currentAudioProcessor && !inputSettings?.audio?.processor) {
        setAudioProcessor(currentAudioProcessor);
      }
    }, [currentAudioProcessor, daily, setAudioProcessor])
  );

  /**
   * Provide debugging functionality
   */
  useEffect(() => {
    window['dailyDisablePrebuiltDebugger'] = disablePrebuiltDebugger;
    window['dailyEnablePrebuiltDebugger'] = enablePrebuiltDebugger;

    if ('debug' in query) {
      enablePrebuiltDebugger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  /**
   * Listen for live-streaming errors and show a snackbar in case.
   */
  useLiveStreaming({
    onLiveStreamingError: useCallback(() => {
      addMessage({
        content: t('notification.liveStreamingError'),
        type: 'error',
      });
    }, [addMessage, t]),
  });

  useThrottledDailyEvent(
    'participant-joined',
    useCallback((evts) => {
      // the "waiting for host" message only shows before a host has joined
      if (evts.some((ev) => ev.participant.owner))
        setDisableWaitingForHostMsg(true);
    }, [])
  );

  const localSessionId = useLocalSessionId();
  const [
    audioMutedByRemoteRequest,
    videoMutedByRemoteRequest,
    canAdmin,
    isOwner,
  ] = useParticipantProperty(localSessionId, [
    'tracks.audio.off.byRemoteRequest',
    'tracks.video.off.byRemoteRequest',
    'permissions.canAdmin',
    'owner',
  ]);

  const prevCanAdmin = usePreviousValue(canAdmin);
  useEffect(() => {
    if (isOwner) return;
    if (canAdmin && !prevCanAdmin) {
      addMessage({
        content: t('notification.adminPermissionsGranted'),
        type: 'info',
        icon: <AdminIcon size={16} />,
      });
    } else if (!canAdmin && prevCanAdmin) {
      addMessage({
        content: t('notification.adminPermissionsRevoked'),
        type: 'info',
        icon: <AdminIcon size={16} />,
      });
    }
  }, [addMessage, canAdmin, isOwner, prevCanAdmin, t]);
  /**
   * Display a muted by host message, in case the participant's mic got muted.
   */
  useEffect(() => {
    if (!audioMutedByRemoteRequest) return;
    addMessage({
      content: t('notification.micMutedByHost'),
      type: 'info',
      icon: <MicrophoneIcon muted size={16} />,
    });
  }, [addMessage, audioMutedByRemoteRequest, t]);
  /**
   * Display a muted by host message, in case the participant's cam got muted.
   */
  useEffect(() => {
    if (!videoMutedByRemoteRequest) return;
    addMessage({
      content: t('notification.camMutedByHost'),
      type: 'info',
      icon: (
        <CamIcon
          id={`snackbarIcon${Date.now()}`} // randomize ID in case of multiple snackbars
          muted
          size={16}
        />
      ),
    });
  }, [addMessage, videoMutedByRemoteRequest, t]);

  /**
   * Play error sound on 'knocking-denied' CallState.
   * TODO: Refactor to 'useEvent' once it's available.
   */
  useEffect(() => {
    if (state !== 'knocking-denied') return;
    errorSound.play();
  }, [errorSound, state]);

  const isAutoPIPEnabled = useIsAutoPIPEnabled();
  const setIsAutoPIPEnabled = useSetIsAutoPIPEnabled();
  const isPIP = useIsPIP();
  const setIsPIP = useSetIsPIP();
  const userData = useLocalUserFlownUserData();
  const isHost = !!userData?.isHost;

  const shouldRunAutoPiP = isAutoPIPEnabled && !isHost;

  const autoPiP = useCallback(() => {
    if (state !== 'joined') return;
    if (!shouldRunAutoPiP) {
      setIsAutoPIPEnabled(false);
      return;
    }
    if (!pipRef.current) return;

    pipRef.current
      .requestPictureInPicture()
      .then(() => {
        setIsPIP(true);
      })
      .catch((e) => {
        console.error('Error with APP level PIP' + e);
        captureException(e);
        setIsPIP(false);
      });
  }, [shouldRunAutoPiP, setIsPIP, state, setIsAutoPIPEnabled]);

  // Pass the atom value to both functions
  useEffect(() => {
    if (!pipRef.current || !isAutoPIPEnabled) {
      window.removeEventListener('blur', autoPiP);
      return;
    }

    window.addEventListener('blur', autoPiP);

    return () => window.removeEventListener('blur', autoPiP);
  }, [autoPiP, isAutoPIPEnabled]);

  const messageProps: React.ComponentProps<typeof FinalMessage> =
    useMemo(() => {
      switch (state) {
        case 'ended':
          return {
            desc: t('lobby.ended.desc'),
            title: t('lobby.ended.title'),
            variant: 'error',
          };
        case 'error':
          return {
            desc: t('lobby.error.desc'),
            title: t('lobby.error.title'),
            variant: 'error',
          };
        case 'expired':
          return {
            desc: t('lobby.expired.desc'),
            title: t('lobby.expired.title'),
            variant: 'error',
          };
        case 'full':
          return {
            desc: t('lobby.full.desc'),
            title: t('lobby.full.title'),
            variant: 'error',
          };
        case 'joining':
          if (
            (camState === 'pending' || micState === 'pending') &&
            broadcast !== null &&
            broadcastRole !== 'attendee'
          ) {
            return {
              desc: t('lobby.devicePermissions.desc'),
              title: t('lobby.devicePermissions.title'),
            };
          }
          break;
        case 'knocking-cancelled':
          return mode === 'direct-link'
            ? { showLoadingScreen: true }
            : {
                desc: t('lobby.canceled.desc'),
                title: t('lobby.canceled.title'),
              };
        case 'knocking-denied':
          return {
            desc: t('lobby.denied.desc'),
            icon: <CloseIcon color={colors.system.red} />,
            title: t('lobby.denied.title'),
          };
        case 'left':
          return {
            cta: poweredByDaily && (
              <Button href="https://www.daily.co" variant="secondary">
                {t('lobby.left.learnMore')}
              </Button>
            ),
            desc: poweredByDaily
              ? t('lobby.left.descBranded')
              : 'Taking you back to the Launchpad',
            icon: <Text variant="largestrong">👋</Text>,
            poweredByDaily,
            title: `You've left the call`,
          };
        case 'nbf':
          return {
            cta:
              mode === 'direct-link' ? (
                <Button id="nbf-reload" onClick={reloadCall} variant="primary">
                  {t('general.tryAgain')}
                </Button>
              ) : null,
            desc: t('lobby.notAvailable.desc'),
            title: t('lobby.notAvailable.title'),
            variant: 'error',
          };
        case 'not-allowed':
          return {
            desc: t('lobby.noAccess.desc'),
            title: t('lobby.noAccess.title'),
            variant: 'error',
          };
        case 'not-found':
          return {
            desc: t('lobby.notFound.desc'),
            title: t('lobby.notFound.title'),
            variant: 'error',
          };
        case 'not-secure':
          return {
            desc: t('lobby.notSecure.desc'),
            title: t('lobby.notSecure.title'),
            variant: 'error',
          };
        case 'removed-from-call':
          return {
            desc: t('lobby.removed.desc'),
            title: t('lobby.removed.title'),
            variant: 'error',
          };
      }
      return null;
    }, [
      broadcast,
      broadcastRole,
      camState,
      colors.system.red,
      micState,
      mode,
      poweredByDaily,
      reloadCall,
      state,
      t,
    ]);

  if (mode === 'embedded' && state === 'redirecting') {
    // Don't redirect in embedded mode, just clear out UI
    return null;
  }

  if (messageProps) {
    return (
      <Layout>
        <FinalMessage {...messageProps} />
      </Layout>
    );
  }

  /**
   * A host must be present for OOB attendees to see the in-call UI.
   * Once attendees have entered the call, this message won't be shown
   * again.
   */
  const waitingForHost =
    broadcastRole === 'attendee' &&
    ownerParticipantIds.length === 0 &&
    !disableWaitingForHostMsg;

  const loading =
    ['awaiting-args', 'ready', 'joining'].includes(state) ||
    // Wait for localParticipant to be present
    !localSessionId;

  return (
    <Layout>
      {messageProps ? (
        <FinalMessage {...messageProps} />
      ) : loading ? (
        <LoadingScreen />
      ) : state === 'lobby' ? (
        <Haircheck />
      ) : waitingForHost ? (
        <WaitingForHost />
      ) : (
        <WaitingRoomProvider>
          <Call />
          <FlownTray />
        </WaitingRoomProvider>
      )}
      <FlownAudioTracks />
      <PictureInPicture
        ref={pipRef}
        enabled={isPIP}
        onLeavePIP={() => setIsPIP(false)}
      />
    </Layout>
  );
};
