export const APP_MESSAGES = {
  'report-remove-participant': 'report-remove-participant',
  'br-open': 'br-open',
  'br-close': 'br-close',
  'br-started': 'br-started',
  'br-ended': 'br-ended',
  'close-settings': 'close-settings',
  'open-settings': 'open-settings',
  'toggle-spotlight': 'toggle-spotlight',
  'background-open': 'background-open',
  'share-audio-start': 'share-audio-start',
  'share-audio-stop': 'share-audio-stop',
  'leave-call-intent': 'leave-call-intent',
  'hide-participant': 'hide-participant',
};
