import {
  useActiveSpeakerId,
  useDailyEvent,
  useLocalSessionId,
  useParticipantProperty,
} from '@daily-co/daily-react';
import { useCallback, useRef, useState } from 'react';

import {
  useFilteredParticipantIds,
  useOrderedParticipantIds,
} from '/contexts/ParticipantsProvider';
import { isTrackOff } from '/lib/participants';
import { useShowLocalVideo } from '/lib/state/custom';

import { useCallConfig } from './useCallConfig';

/**
 * Convenience hook to contain all logic on determining the active speaker
 * (= the current one and only actively speaking person)
 */
export const useActiveSpeaker = () => {
  const [showLocalVideo] = useShowLocalVideo();
  const { broadcastRole } = useCallConfig();
  const localSessionId = useLocalSessionId();
  const orderedParticipantIds = useOrderedParticipantIds();
  const activeSpeakerId = useActiveSpeakerId({
    filter: useCallback(
      (id: string) => {
        const isRemoteSpeaker = orderedParticipantIds.includes(id);
        if (orderedParticipantIds.length)
          return isRemoteSpeaker && id !== localSessionId;
        return id === localSessionId;
      },
      [localSessionId, orderedParticipantIds]
    ),
  });
  const activeSpeakerAudioState = useParticipantProperty(
    activeSpeakerId,
    'tracks.audio.state'
  );
  const localAudioTrackState = useParticipantProperty(
    localSessionId,
    'tracks.audio.state'
  );
  const filteredParticipantIds = useFilteredParticipantIds();

  const remoteAudioLevels = useRef<Record<string, number>>({});
  const [subgroupSpeaker, setSubgroupSpeaker] = useState<string>(null);
  /**
   * Determine subgroupSpeaker by comparing remote audio levels.
   */
  useDailyEvent(
    'remote-participants-audio-level',
    useCallback(
      (ev) => {
        if (!filteredParticipantIds) return;

        remoteAudioLevels.current = ev.participantsAudioLevel;
        const loudest = Object.entries(ev.participantsAudioLevel)
          .filter(([id]) => filteredParticipantIds.includes(id))
          .reduce((loudest, row) => {
            if (!loudest || row[1] > loudest?.[1]) return row;
            return loudest;
          }, null);
        if (!loudest) return;
        setSubgroupSpeaker(loudest[0]);
      },
      [filteredParticipantIds]
    )
  );

  if (Array.isArray(filteredParticipantIds)) {
    return subgroupSpeaker ?? null;
  }

  // we don't show active speaker indicators EVER in a 1:1 call or when the user is alone in-call
  if (broadcastRole !== 'attendee' && orderedParticipantIds.length < 2)
    return null;

  // active speaker as reported by daily-js is currently not in the list of participants of interest
  if (!orderedParticipantIds.includes(activeSpeakerId)) return null;

  if (!isTrackOff(activeSpeakerAudioState)) {
    return activeSpeakerId;
  }

  /**
   * When the local video is displayed and the last known active speaker
   * is muted, we can only fall back to the local participant.
   */
  return isTrackOff(localAudioTrackState) || !showLocalVideo
    ? null
    : localSessionId;
};
