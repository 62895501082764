import { Card, CardContent } from '@daily/shared/components/Card';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';

import { PoweredByDailyBanner } from '/components/PoweredByDailyBanner';

import { LoadingScreen } from './LoadingScreen';

type Variant = 'default' | 'error' | 'success';
type TextColor = React.ComponentProps<typeof Text>['color'];

interface Props {
  cta?: JSX.Element;
  desc?: string;
  icon?: JSX.Element;
  poweredByDaily?: boolean;
  showLoadingScreen?: boolean;
  title?: string;
  variant?: Variant;
}

export const FinalMessage: React.FC<React.PropsWithChildren<Props>> = ({
  cta = null,
  desc,
  icon = null,
  poweredByDaily = false,
  showLoadingScreen = false,
  title,
  variant = 'default',
}) => {
  if (showLoadingScreen) return <LoadingScreen />;

  const titleColor: Record<Variant, TextColor> = {
    default: 'default',
    error: 'error',
    success: 'success',
  };

  return (
    <>
      {poweredByDaily && <PoweredByDailyBanner />}
      <div className="container">
        <Stack>
          <Card>
            <CardContent>
              <Stack align="center" gap={16}>
                <Stack align="center">
                  {icon}
                  <Text
                    color={titleColor[variant]}
                    textAlign="center"
                    variant="largestrong"
                  >
                    {title}
                  </Text>
                  {desc && <Text textAlign="center">{desc}</Text>}
                </Stack>
                {cta}
              </Stack>
            </CardContent>
          </Card>
        </Stack>
        <style jsx>{`
          .container {
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;
            overflow: auto;
            width: 100%;
            padding: 8px;
            color: white;
          }
          .container :global(.card-content) {
            padding: 32px;
          }
        `}</style>
      </div>
    </>
  );
};
