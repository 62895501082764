import { Card, CardContent } from '@daily/shared/components/Card';
import { LoadingSpinner } from '@daily/shared/components/LoadingSpinner';
import { Stack } from '@daily/shared/components/Stack';
import { Text } from '@daily/shared/components/Text';
import { useTheme } from '@daily/shared/contexts/Theme';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PoweredByDailyBanner } from '/components/PoweredByDailyBanner';
import { useCallConfig } from '/hooks/useCallConfig';
import { useStyleVariants } from '/hooks/useStyleVariants';

export const WaitingForHost: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { t } = useTranslation();
  const { textVariant } = useStyleVariants();
  const { colors, mediaQueries, zIndex } = useTheme();
  const [showText, setShowText] = useState(false);
  const { poweredByDaily } = useCallConfig();

  /**
   * This waiting message only shows if there is no owner/host present.
   * Add a delay to avoid the message flashing before the participant
   * count has updated correctly.
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container">
      {poweredByDaily && (
        <div className="banner">
          <PoweredByDailyBanner />
        </div>
      )}
      {showText && (
        <div className="waitingForHost">
          <Stack>
            <Card>
              <CardContent>
                <Stack align="center">
                  <LoadingSpinner />
                  <Text variant="largestrong">
                    {t('broadcast.waitingForHostTitle')}
                  </Text>
                  <Text textAlign="center" variant={textVariant}>
                    {t('broadcast.waitingForHostSubtitle')}
                  </Text>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </div>
      )}
      <style jsx>{`
        .container {
          align-items: center;
          display: flex;
          height: 100%;
          overflow: auto;
          padding: 46px 16px 16px;
          width: 100%;
          background: ${colors.custom.mainAreaBg};
          color: ${colors.accentText};
        }
        .waitingForHost {
          align-items: center;
          justify-content: center;
          display: flex;
          height: 100%;
          width: 100%;
          padding: 8px;
        }
        .waitingForHost :global(.card-content) {
          padding: 32px;
        }
        @media ${mediaQueries.nonCoarse} {
          .haircheck > :global(*:not(.banner)) {
            margin: auto;
          }
        }
        .banner {
          flex: none;
          left: 0;
          position: fixed;
          top: 0;
          width: 100vw;
          z-index: ${zIndex.header};
        }
      `}</style>
    </div>
  );
};
